import _ from 'lodash'
import { UserPrivilegeLevels } from 'mgr/lib/utils/Constants'
import * as ActionTypes from 'mgr/pages/venue-group/actions/ActionTypes'
import { DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL, DEPENDED_PERMISSIONS } from 'mgr/pages/venue-group/constants'

const emailPermissions = [
  'receive_daily_summary_email',
  'receive_special_occasions_email',
  'receive_feedback_summary_email',
  'receive_feedback_alert_email',
  'receive_api_request_email',
  'receive_ipad_summary_email',
  'receive_resolution_report_email',
  'receive_insights_summary_email',
  'receive_third_party_res_alert_email',
  'receive_internal_res_alert_email',
  'f_make_request_follower',
  'receive_membership_alerts_email',
  'receive_ipad_tally_email',
  'receive_confirm_confirmation_email',
  'receive_follower_alert_email',
  'receive_follower_new_res_alert_email',
]

const editablePermissionsByPriv = {
  [UserPrivilegeLevels.SUPERUSER]:
    [
      'f_request_autoassign',
      'f_data_user_export',
      'f_make_reservation_follower',
      'f_make_reservation_booker_follower',
      'f_allow_edit_loyalty',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
      'f_data_actuals_export',
      'f_data_client_export',
      'f_only_notes',
    ] + emailPermissions,
  [UserPrivilegeLevels.MANAGER]:
    [
      'f_tag_client_edit',
      'f_tag_client_edit_restricted',
      'f_general_settings_admin',
      'f_payment_gateway_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_allow_edit_loyalty',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_user_admin',
      'f_widget_settings_admin',
      'f_request_autoassign',
      'f_can_charge',
      'f_can_refund',
      'f_can_edit_maximum_total_covers_for_shift',
      'f_data_actuals_export',
      'f_data_client_export',
      'f_data_user_export',
      'f_make_reservation_follower',
      'f_make_reservation_booker_follower',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_bookedby_manual_input_required',
      'f_view_all_contact_info',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_view_reporting_full',
      'f_3p_client_db_search',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'f_access_vip_chat',
      'f_send_vip_chat',
      'f_guestlist_access',
      'f_send_custom_sms_on_mobile',
      'f_has_floorplan_edit_permission',
      'f_access_insights_page',
      'f_access_private_events_tab',
      'f_hide_actuals_report',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
      'f_access_private_events_tab',
      'f_require_approval',
      'f_only_notes',
      'f_manage_room_invites',
      'f_revoke_room_invites',
    ] + emailPermissions,
  [UserPrivilegeLevels.SUBMANAGER]:
    [
      'f_general_settings_admin',
      'f_can_edit_booking_block',
      'f_can_edit_maximum_total_covers_for_shift',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_allow_edit_loyalty',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_widget_settings_admin',
      'f_tag_client_edit',
      'f_tag_client_edit_restricted',
      'f_has_floorplan_edit_permission',
      'f_request_autoassign',
      'f_book_ot_rules',
      'f_can_charge',
      'f_can_refund',
      'f_data_actuals_export',
      'f_data_client_export',
      'f_data_user_export',
      'f_make_reservation_follower',
      'f_make_reservation_booker_follower',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_bookedby_manual_input_required',
      'f_view_all_contact_info',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_view_reporting_full',
      'f_3p_client_db_search',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'f_access_vip_chat',
      'f_send_vip_chat',
      'f_guestlist_access',
      'f_send_custom_sms_on_mobile',
      'f_access_insights_page',
      'f_access_private_events_tab',
      'f_hide_actuals_report',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
      'f_require_approval',
      'f_only_notes',
      'f_manage_room_invites',
      'f_revoke_room_invites',
      'f_user_admin',
    ] + emailPermissions,
  [UserPrivilegeLevels.BASIC]:
    [
      'f_request_autoassign',
      'f_guestlist_access',
      'f_make_reservation_follower',
      'f_make_reservation_booker_follower',
      'f_require_approval',
      'f_bookedby_manual_input_required',
      'f_all_reservations',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_allow_edit_loyalty',
      'f_access_vip_chat',
      'f_send_vip_chat',
      'f_send_custom_sms_on_mobile',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
    ] + emailPermissions,
  [UserPrivilegeLevels.USERMANAGER]: ['f_data_user_export'] + emailPermissions,
}

const presetRoleTemplates = [
  {
    value: 'CORPORATE_DIRECTOR_CEO',
    name: 'Corporate Director / CEO',
    privilege_level: UserPrivilegeLevels.SUPERUSER,
    subtext: 'Export and view all client and actuals data, administrate settings, book and view all reservations, modify availability',
    caption: 'Ex: Director, VP, SVP, CEO',
    default_on_permissions: [
      'f_only_notes',
      'f_venue_settings',
      'f_availability',
      'f_view_reporting_full',
      'f_view_individual_reporting',
      'f_can_edit_booking_block',
      'f_can_edit_maximum_total_covers_for_shift',
      'f_tag_client_edit',
      'f_tag_client_edit_restricted',
      'f_general_settings_admin',
      'f_payment_gateway_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_allow_edit_loyalty',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_user_admin',
      'f_has_floorplan_edit_permission',
      'f_guestlist_access',
      'f_book_ot_rules',
      'f_can_charge',
      'f_can_refund',
      'f_data_actuals_export',
      'f_data_client_export',
      'f_data_user_export',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_view_all_contact_info',
      'f_all_reservations',
      'f_send_custom_sms_on_mobile',
      'f_access_rule_bulk_copy',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_3p_client_db_search',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_widget_settings_admin',
      'f_access_private_events_tab',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'f_make_reservation_booker_follower',
      'f_access_vip_chat',
      'f_send_vip_chat',
      'f_access_insights_page',
      'f_hide_actuals_report',
      'receive_insights_summary_email',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
      'f_manage_room_invites',
      'f_revoke_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.SUPERUSER],
  },
  {
    value: 'GENERAL_MANAGER_ADVANCED',
    name: 'General Manager (Advanced)',
    privilege_level: UserPrivilegeLevels.SUPERUSER,
    subtext: 'Export and view all client and actuals data, administrate settings, book and view all reservations, modify availability',
    caption: 'Ex: General Manager, Property Director',
    default_on_permissions: [
      'f_only_notes',
      'f_venue_settings',
      'f_availability',
      'f_view_reporting_full',
      'f_view_individual_reporting',
      'f_can_edit_booking_block',
      'f_can_edit_maximum_total_covers_for_shift',
      'f_tag_client_edit',
      'f_tag_client_edit_restricted',
      'f_general_settings_admin',
      'f_payment_gateway_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_allow_edit_loyalty',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_user_admin',
      'f_has_floorplan_edit_permission',
      'f_guestlist_access',
      'f_book_ot_rules',
      'f_can_charge',
      'f_can_refund',
      'f_data_actuals_export',
      'f_data_client_export',
      'f_data_user_export',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_view_all_contact_info',
      'f_all_reservations',
      'f_send_custom_sms_on_mobile',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_widget_settings_admin',
      'f_3p_client_db_search',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'receive_special_occasions_email',
      'receive_feedback_summary_email',
      'receive_ipad_summary_email',
      'receive_daily_summary_email',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_make_reservation_booker_follower',
      'f_access_vip_chat',
      'f_send_vip_chat',
      'f_access_insights_page',
      'f_hide_actuals_report',
      'receive_insights_summary_email',
      'f_can_book_outside_of_the_shift_internal_booking_limit',
      'f_access_private_events_tab',
      'f_manage_room_invites',
      'f_revoke_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.SUPERUSER],
  },
  {
    value: 'GENERAL_MANAGER_STANDARD',
    name: 'General Manager (Standard)',
    privilege_level: UserPrivilegeLevels.MANAGER,
    subtext: 'View all client and actuals data, administrate settings, book and view all reservations, modify availability',
    caption: 'Ex: General Manager, Asst. General Manager',
    default_on_permissions: [
      'f_venue_settings',
      'f_availability',
      'f_view_reporting_full',
      'f_view_individual_reporting',
      'f_general_settings_admin',
      'f_payment_gateway_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_send_custom_sms_on_mobile',
      'f_can_edit_booking_block',
      'f_guestlist_access',
      'f_book_ot_rules',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_all_reservations',
      'f_view_all_contact_info',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_can_charge',
      'f_can_refund',
      'f_has_floorplan_edit_permission',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_widget_settings_admin',
      'f_3p_client_db_search',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'receive_special_occasions_email',
      'receive_resolution_report_email',
      'receive_feedback_summary_email',
      'receive_ipad_summary_email',
      'receive_daily_summary_email',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_access_insights_page',
      'f_hide_actuals_report',
      'f_make_reservation_booker_follower',
      'receive_insights_summary_email',
      'f_access_private_events_tab',
      'f_manage_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.MANAGER],
  },
  {
    value: 'MARKETING_MANAGER',
    name: 'Marketing Manager',
    privilege_level: UserPrivilegeLevels.MANAGER,
    subtext: 'Export and view all client data, administrate settings, book and view all reservations, modify availability',
    caption: 'Ex: Head of Marketing, Marketing coordinator',
    default_on_permissions: [
      'f_venue_settings',
      'f_availability',
      'f_view_reporting_full',
      'f_view_individual_reporting',
      'f_general_settings_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_send_custom_sms_on_mobile',
      'f_3p_client_db_search',
      'f_data_client_export',
      'f_view_all_contact_info',
      'f_can_edit_booking_block',
      'f_guestlist_access',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_book_ot_rules',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_all_reservations',
      'f_has_floorplan_edit_permission',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_widget_settings_admin',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'f_make_reservation_booker_follower',
      'f_access_insights_page',
      'f_hide_actuals_report',
      'receive_insights_summary_email',
      'f_access_private_events_tab',
      'f_manage_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.MANAGER],
  },
  {
    value: 'RESTAURANT_MANAGER',
    name: 'Restaurant Manager',
    privilege_level: UserPrivilegeLevels.MANAGER,
    subtext: 'View all client data, add/remove client tags, charge credit cards, overbook, manage blocks, modify availability',
    caption: 'Ex: Restaurant Manager, Supervisor',
    default_on_permissions: [
      'f_venue_settings',
      'f_availability',
      'f_view_reporting_full',
      'f_view_individual_reporting',
      'f_general_settings_admin',
      'f_payment_gateway_admin',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_access_marketing_tools',
      'f_emails_with_global_tags',
      'f_send_custom_sms_on_mobile',
      'f_can_edit_booking_block',
      'f_guestlist_access',
      'f_reservation_tag_list_admin',
      'f_manage_global_auto_tags',
      'f_client_tag_list_admin',
      'f_book_ot_rules',
      'f_book_no_tables',
      'f_book_larger_tables',
      'f_book_smaller_tables',
      'f_overbook_pacing',
      'f_overbook_enforced_shift_party_size',
      'f_book_wo_payment',
      'f_all_reservations',
      'f_can_charge',
      'f_can_refund',
      'f_view_all_contact_info',
      'f_has_floorplan_edit_permission',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_widget_settings_admin',
      'f_3p_client_db_search',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_shifts_access_rules_admin',
      'f_blackout_dates_daily_program_admin',
      'f_make_reservation_booker_follower',
      'f_access_insights_page',
      'f_hide_actuals_report',
      'receive_insights_summary_email',
      'f_access_private_events_tab',
      'f_manage_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.MANAGER],
  },
  {
    value: 'HOST_RESERVATIONIST_ADVANCED',
    name: 'Host/Reservationist (Advanced)',
    privilege_level: UserPrivilegeLevels.SUBMANAGER,
    subtext: 'View all client data, add/remove client tags, charge credit cards, overbook, manage blocks',
    caption: 'Ex: Experienced host, lead reservationist',
    default_on_permissions: [
      'f_view_individual_reporting',
      'f_has_floorplan_edit_permission',
      'f_can_edit_booking_block',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_overbook_pacing',
      'f_3p_client_db_search',
      'f_book_ot_rules',
      'f_book_no_tables',
      'f_view_all_contact_info',
      'f_book_smaller_tables',
      'f_book_larger_tables',
      'f_book_wo_payment',
      'f_guestlist_access',
      'f_can_charge',
      'f_can_refund',
      'f_all_reservations',
      'f_send_custom_sms_on_mobile',
      'receive_daily_summary_email',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_make_reservation_booker_follower',
      'f_manage_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.SUBMANAGER],
  },
  {
    value: 'HOST_RESERVATIONIST_STANDARD',
    name: 'Host/Reservationist (Standard)',
    privilege_level: UserPrivilegeLevels.SUBMANAGER,
    subtext: 'View all client data, add/remove client tags, charge credit cards',
    caption: 'Ex: Host, Reservationist',
    default_on_permissions: [
      'f_view_individual_reporting',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
      'f_3p_client_db_search',
      'f_view_all_contact_info',
      'f_guestlist_access',
      'f_can_charge',
      'f_can_refund',
      'f_all_reservations',
      'f_send_custom_sms_on_mobile',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_make_reservation_booker_follower',
      'f_manage_room_invites',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.SUBMANAGER],
  },
  {
    value: 'IT_USER_ADMINISTRATOR',
    name: 'IT User Administrator',
    privilege_level: UserPrivilegeLevels.USERMANAGER,
    subtext: 'Administrate User Access only',
    caption: 'Ex: IT Admin, IT Coordinator',
    default_on_permissions: ['f_data_user_export', 'f_user_admin'],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.USERMANAGER],
  },
  {
    value: 'PROMOTER',
    name: 'Promoter',
    privilege_level: UserPrivilegeLevels.BASIC,
    subtext: 'View own reservations only',
    caption: 'Ex: Promoter, external booker',
    default_on_permissions: [
      'f_view_individual_reporting',
      'f_send_custom_sms_on_mobile',
      'f_view_client_spend_data',
      'f_view_pos_check_data',
      'f_access_operations_tab',
      'f_access_requests_tab',
      'f_access_client_tab',
      'f_view_clients_across_venue_group',
    ],
    editable_permissions: editablePermissionsByPriv[UserPrivilegeLevels.BASIC],
  },
]

const defaultOnPermissionsByLevel = {
  f_view_client_spend_data: [UserPrivilegeLevels.SUPERUSER],
  f_3p_client_db_search: [UserPrivilegeLevels.SUPERUSER, UserPrivilegeLevels.MANAGER, UserPrivilegeLevels.SUBMANAGER],
  f_access_rule_bulk_copy: [UserPrivilegeLevels.SUPERUSER],
  f_venue_settings: [UserPrivilegeLevels.SUPERUSER, UserPrivilegeLevels.MANAGER],
  f_view_reporting_full: [UserPrivilegeLevels.SUPERUSER, UserPrivilegeLevels.MANAGER],
  f_view_individual_reporting: [
    UserPrivilegeLevels.SUPERUSER,
    UserPrivilegeLevels.MANAGER,
    UserPrivilegeLevels.SUBMANAGER,
    UserPrivilegeLevels.BASIC,
  ],
}

const initialStatePermissions = {
  f_only_notes: false,
  f_venue_settings: false,
  f_access_marketing_tools: false,
  f_emails_with_global_tags: false,
  f_availability: false,
  f_view_reporting_full: false,
  f_view_individual_reporting: false,
  f_3p_client_db_search: false,
  f_all_reservations: false,
  f_allow_request_summary: false,
  f_book_larger_tables: false,
  f_book_smaller_tables: false,
  f_book_no_tables: false,
  f_book_ot_rules: false,
  f_book_wo_payment: false,
  f_bookedby_manual_input_required: false,
  f_can_charge: false,
  f_can_refund: false,
  f_can_edit_booking_block: false,
  f_data_actuals_export: false,
  f_data_client_export: false,
  f_data_user_export: false,
  f_guestlist_access: false,
  f_guestlist_own_access: false,
  f_guestlist_own_access_not_others: false,
  f_has_floorplan_edit_permission: false,
  f_make_request_follower: false,
  f_make_reservation_follower: false,
  f_make_reservation_booker_follower: false,
  f_overbook_pacing: false,
  f_overbook_enforced_shift_party_size: false,
  f_general_settings_admin: false,
  f_payment_gateway_admin: false,
  f_access_client_tab: false,
  f_view_clients_across_venue_group: false,
  f_allow_edit_loyalty: false,
  f_request_autoassign: false,
  f_require_approval: false,
  f_send_custom_sms_on_mobile: false,
  f_access_rule_bulk_copy: false,
  f_access_operations_tab: false,
  f_access_requests_tab: false,
  f_reservation_tag_list_admin: false,
  f_client_tag_list_admin: false,
  f_tag_client_edit: false,
  f_tag_client_edit_restricted: false,
  f_user_admin: false,
  f_view_all_contact_info: false,
  f_view_client_spend_data: false,
  f_view_pos_check_data: false,
  f_widget_settings_admin: false,
  f_shifts_access_rules_admin: false,
  f_can_edit_maximum_total_covers_for_shift: false,
  f_blackout_dates_daily_program_admin: false,
  f_access_vip_chat: false,
  f_send_vip_chat: false,
  f_access_insights_page: false,
  f_access_private_events_tab: false,
  f_hide_actuals_report: false,
  f_manage_global_auto_tags: false,
  receive_api_request_email: false,
  receive_confirm_confirmation_email: false,
  receive_daily_summary_email: false,
  receive_feedback_alert_email: false,
  receive_feedback_summary_email: false,
  receive_follower_alert_email: false,
  receive_follower_new_res_alert_email: false,
  receive_internal_res_alert_email: false,
  receive_ipad_summary_email: false,
  receive_ipad_tally_email: false,
  receive_membership_alerts_email: false,
  receive_resolution_report_email: false,
  receive_special_occasions_email: false,
  receive_third_party_res_alert_email: false,
  receive_insights_summary_email: false,
  f_can_book_outside_of_the_shift_internal_booking_limit: false,
  f_manage_room_invites: false,
  f_revoke_room_invites: false,
}

const initialStatePermissionNames = _.keys(initialStatePermissions)

const initialState = {
  vmsRoleTemplates: [],
  formattedVmsRoleTemplates: [],
  vmsRoleTemplateChoices: [],
  selectedRoleValid: true,
  selectedRoleId: '',
  selectedRoleName: '',
  selectedPresetRoleTemplateId: '',
  selectedRolePrivilegeLevel: '',
  presetRoleTemplates,
  permissions: initialStatePermissions,
  isRoleUpdating: false,
  rolesListIsLoading: false,
  showWarning: false,
}

const rolesReducer = (state = initialState, action) => {
  const getPresetRoleTemplate = presetRoleTemplateId => _.find(state.presetRoleTemplates, { value: presetRoleTemplateId })

  switch (action.type) {
    case ActionTypes.UPDATE_EDITABLE_PERMISSIONS: {
      if (!action.shouldLockEmailCampaignsWithGlobalTags) {
        return state
      }

      const updatedRoleTemplates = state.presetRoleTemplates.map(presetRoleTemplate => {
        let updatedEditablePermissions = presetRoleTemplate.editable_permissions
        const editablePermissions = updatedEditablePermissions.split(',')
        const index = editablePermissions.findIndex(permission => permission === 'f_emails_with_global_tags')
        if (index !== -1) {
          editablePermissions.splice(index, 1)
          updatedEditablePermissions = editablePermissions.join(',')
        }
        return {
          ...presetRoleTemplate,
          editable_permissions: updatedEditablePermissions,
        }
      })
      return {
        ...state,
        presetRoleTemplates: updatedRoleTemplates,
      }
    }
    case ActionTypes.LOAD_VMS_ROLE_TEMPLATES_START:
      return {
        ...state,
        rolesListIsLoading: true,
      }
    case ActionTypes.LOAD_VMS_ROLE_TEMPLATES_SUCCESS:
      const formattedVmsRoleTemplates = _.map(action.vmsRoleTemplates, vmsRoleTemplate => ({
        name: vmsRoleTemplate.name,
        value: vmsRoleTemplate.id,
      }))
      const vmsRoleTemplateChoices = formattedVmsRoleTemplates.slice(0)
      vmsRoleTemplateChoices.unshift({ name: 'All', value: 'All' })
      return {
        ...state,
        vmsRoleTemplates: action.vmsRoleTemplates,
        formattedVmsRoleTemplates,
        vmsRoleTemplateChoices,
        rolesListIsLoading: false,
      }
    case ActionTypes.LOAD_VMS_ROLE_TEMPLATE_SUCCESS:
      const vmsRolePermissions = {}
      _.map(initialStatePermissionNames, propName => {
        if (action.vmsRoleTemplate[propName] === null) {
          if (propName === 'f_can_refund') {
            if (action.vmsRoleTemplate.f_can_charge !== null) {
              vmsRolePermissions.f_can_refund = action.vmsRoleTemplate.f_can_charge
            } else {
              vmsRolePermissions.f_can_refund = !!(
                DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL[action.vmsRoleTemplate.privilege_level].f_can_charge ?? true
              )
            }
            return
          }
          if (DEPENDED_PERMISSIONS[propName]) {
            if (DEPENDED_PERMISSIONS[propName] in action.vmsRoleTemplate) {
              vmsRolePermissions[propName] = !!action.vmsRoleTemplate[DEPENDED_PERMISSIONS[propName]]
            } else if (DEPENDED_PERMISSIONS[propName] in DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL[action.vmsRoleTemplate.privilege_level]) {
              vmsRolePermissions[propName] =
                !!DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL[action.vmsRoleTemplate.privilege_level][propName] ?? true
            } else {
              vmsRolePermissions[propName] = false
            }
          } else {
            vmsRolePermissions[propName] =
              !!DEFAULT_PERMISSIONS_PER_PRIVILEGE_LEVEL[action.vmsRoleTemplate.privilege_level][propName] ?? true
          }
          return
        }
        vmsRolePermissions[propName] = !!action.vmsRoleTemplate[propName]
      })
      for (const permission in defaultOnPermissionsByLevel) {
        if (defaultOnPermissionsByLevel[permission].includes(action.vmsRoleTemplate.privilege_level)) {
          vmsRolePermissions[permission] = true
        }
      }
      if (vmsRolePermissions.f_make_reservation_follower === true) {
        vmsRolePermissions.f_make_reservation_booker_follower = true
      }
      return {
        ...state,
        selectedRoleId: action.vmsRoleTemplate.id,
        selectedRoleName: action.vmsRoleTemplate.name,
        selectedPresetRoleTemplateId: action.vmsRoleTemplate.preset_role_template,
        selectedRolePrivilegeLevel: action.vmsRoleTemplate.privilege_level,
        permissions: vmsRolePermissions,
      }
    case ActionTypes.PRESET_ROLE_TEMPLATE_CHANGE:
      const presetRoleTemplate = getPresetRoleTemplate(action.presetRoleTemplateId)
      const permissionStateDupe = _.clone(initialStatePermissions)
      _.map(presetRoleTemplate.default_on_permissions, permission => {
        permissionStateDupe[permission] = true
      })
      return {
        ...state,
        permissions: permissionStateDupe,
        selectedPresetRoleTemplateId: action.presetRoleTemplateId,
        selectedRolePrivilegeLevel: presetRoleTemplate.privilege_level,
      }
    case ActionTypes.VMS_ROLE_TEMPLATE_NAME_CHANGE:
      return {
        ...state,
        selectedRoleName: action.roleName,
        selectedRoleValid: true,
      }
    case ActionTypes.TOGGLE_VMS_ROLE_TEMPLATE_PERMISSION:
      const roleTemplate = getPresetRoleTemplate(state.selectedPresetRoleTemplateId)
      if (action.permissionName === 'f_make_reservation_follower') {
        const previousMakeReservationFollowerState = state.permissions.f_make_reservation_follower
        return {
          ...state,
          permissions: {
            ...state.permissions,
            f_make_reservation_follower: !previousMakeReservationFollowerState,
            f_make_reservation_booker_follower: !previousMakeReservationFollowerState,
          },
        }
      }
      const isDisabled = !roleTemplate.editable_permissions.includes(action.permissionName)
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.permissionName]: isDisabled ? state.permissions[action.permissionName] : !state.permissions[action.permissionName],
        },
      }
    case ActionTypes.SET_GROUP_VMS_ROLE_TEMPLATE_PERMISSIONS:
      const selectedRoleTemplate = getPresetRoleTemplate(state.selectedPresetRoleTemplateId)
      const permissionState = state.permissions
      _.map(action.propNames, propName => {
        if (!selectedRoleTemplate.editable_permissions.includes(propName)) {
          return
        }
        permissionState[propName] = action.val
      })
      return {
        ...state,
        permissions: permissionState,
      }
    case ActionTypes.SET_ALLOWED_PERMISSIONS:
      const userSelectedRoleTemplate = getPresetRoleTemplate(state.selectedPresetRoleTemplateId)
      const allPermissions = state.permissions
      _.map(Object.keys(allPermissions), propName => {
        if (!userSelectedRoleTemplate.editable_permissions.includes(propName)) {
          return
        }
        allPermissions[propName] = false
      })
      _.map(action.propNames, propName => {
        if (!userSelectedRoleTemplate.editable_permissions.includes(propName)) {
          return
        }
        allPermissions[propName] = true
      })
      return {
        ...state,
        permissions: allPermissions,
      }
    case ActionTypes.RESET_VMS_ROLE_TEMPLATE_STATE:
      return initialState
    case ActionTypes.INVALIDATE_ROLE:
      return {
        ...state,
        selectedRoleValid: false,
      }
    case ActionTypes.TRY_CREATE_VMS_ROLE_TEMPLATE_START:
      const permissionValues = state.permissions
      const emailCampaignsWithGlobalTags = permissionValues.f_emails_with_global_tags
      permissionValues.f_emails_with_global_tags = permissionValues.f_access_marketing_tools && emailCampaignsWithGlobalTags
      permissionValues.f_send_vip_chat = permissionValues.f_access_vip_chat && permissionValues.f_send_vip_chat
      return {
        ...state,
        permissions: {
          permissionValues,
        },
      }
    case ActionTypes.DELETE_VMS_ROLE_TEMPLATE_START:
    case ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_START:
      return {
        ...state,
        isRoleUpdating: true,
      }
    case ActionTypes.TRY_CREATE_VMS_ROLE_TEMPLATE_SUCCESS:
    case ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_START:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          f_emails_with_global_tags: state.permissions.f_access_marketing_tools && state.permissions.f_emails_with_global_tags,
          f_send_vip_chat: state.permissions.f_access_vip_chat && state.permissions.f_send_vip_chat,
        },
      }
    case ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_SUCCESS:
    case ActionTypes.DELETE_VMS_ROLE_TEMPLATE_SUCCESS:
    case ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        vmsRoleTemplates: [],
        isRoleUpdating: false,
      }
    case ActionTypes.TRY_SAVE_VMS_ROLE_TEMPLATE_FAIL:
    case ActionTypes.DELETE_VMS_ROLE_TEMPLATE_FAIL:
    case ActionTypes.DUPLICATE_VMS_ROLE_TEMPLATE_FAIL:
      return {
        ...state,
        isRoleUpdating: false,
      }
    case ActionTypes.TOGGLE_DELETE_WARNING:
      return {
        ...state,
        showWarning: action.showWarning,
        selectedRoleId: action.selectedRoleId ?? state.selectedRoleId,
      }
    default:
      return state
  }
}

export default rolesReducer
